import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar, ExternalLink, PlayCircle } from 'lucide-react';
import Carousel from './Carousel';

const projects = [
  {
    title: "Custom License Assignments",
    description: "Developed a custom license management system on GCP, enabling license monitoring, assignment, revocation, and upgrades.",
    period: "October 2024 - Present",
    tech: "GCP & Laravel",
    type: "Cloud",
    images: ["/images/projects/sat_1.jpg?height=300&width=400", "/images/projects/sat_2.jpg?height=300&width=400", "/images/projects/sat_3.jpg?height=300&width=400"]
  },
  {
    title: "Binamandiri",
    description: "Built a website for Binamandiri with a focus on user experience and user engagement.",
    period: "Jan 2024",
    tech: "React",
    type: "Website",
    images: ["/images/projects/biman_1.jpg?height=300&width=400", "/images/projects/biman_2.jpg?height=300&width=400", "/images/projects/biman_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App BTI",
    description: "An Android application for internal goods transfer receipts in the warehouse, sent to the admin using registered local WiFi",
    period: "Nov 2023 - 2024",
    tech: "Flutter",
    type: "Mobile",
    images: ["/images/projects/bti_1.jpg?height=300&width=400", "/images/projects/bti_2.jpg?height=300&width=400", "/images/projects/bti_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App Toyscity Member",
    description: "An Android application that allows members to view their points after purchases, earn points, redeem points, find nearby store locations, explore vendor brands and promotional products, and receive Firebase notifications.",
    period: "May 2023 - 2024",
    tech: "Flutter",
    type: "Mobile",
    link: "toyscity Play Store",
    url: "#",
    images: ["/images/projects/toyscity_1.jpg?height=300&width=400", "/images/projects/toyscity_2.jpg?height=300&width=400", "/images/projects/toyscity_3.jpg?height=300&width=400"]
  },
  {
    title: "Frontend & Backend Toyscity",
    description: "Build a website for users to view their member points and create an admin panel for managing information and data for the back office.",
    period: "Jan 2023 - 2024",
    tech: "Next.js & Node.js",
    type: "Web",
    link: "hipbiz.id/toyscityadmin/",
    url: "https://hipbiz.id/toyscityadmin/",
    images: ["/images/projects/toyscityadmin_1.jpg?height=300&width=400", "/images/projects/toyscityadmin_2.jpg?height=300&width=400", "/images/projects/toyscityadmin_3.jpg?height=300&width=400"]
  },
  {
    title: "Mobile App Intex Sales Counter",
    description: "An Android application designed to facilitate sales by enabling direct input of sales items, generating",
    period: "Nov 2022 - 2024",
    tech: "Flutter",
    type: "Mobile",
    link: "IIIP Sales Counter Play Store",
    url: "#",
    images: ["/images/projects/salesorder_1.jpg?height=300&width=400", "/images/projects/salesorder_2.jpg?height=300&width=400", "/images/projects/salesorder_3.jpg?height=300&width=400"]
  },
  {
    title: "RBO Sales Order",
    description: "An application created by the seller to confirm the sale of products or services to the buyer, including details such as ordered products, quantity, price, and applicable discounts.",
    period: "Jun 2021 - 2024",
    tech: "CodeIgniter",
    type: "Web",
    link: "rbo.hipbiz.id/",
    url: "https://rbo.hipbiz.id/",
    images: ["/images/projects/rbo_1.png?height=300&width=400", "/images/projects/rbo_2.png?height=300&width=400", "/images/projects/rbo_2.png?height=300&width=400"]
  },
  {
    title: "Intex Sales Counter",
    description: "An Android application designed to facilitate sales by enabling direct input of sales items, generating",
    period: "Jun 2021 - 2024",
    tech: "Laravel",
    type: "Web",
    link: "rbo.hipbiz.id/",
    url: "https://hipbiz.id/salescounter/",
    images: ["/images/projects/salescounter_1.png?height=300&width=400", "/images/projects/salescounter_2.png?height=300&width=400", "/images/projects/salescounter_2.png?height=300&width=400"]
  }
];

export function Projects() {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section id="projects" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 md:px-12">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold mb-8 text-center text-gray-800"
        >
          My Projects
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {currentProjects.map((project, index) => (
            <motion.div
              key={index}
              className="h-full"
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="bg-white rounded-md border border-gray-200 shadow-sm overflow-hidden transition-all flex flex-col h-full">
                <Carousel images={project.images} />
                <div className="p-4 flex-grow flex flex-col">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-lg font-medium text-gray-800">{project.title}</h3>
                    <span className="text-xs bg-gray-200 text-gray-600 px-2 py-1 rounded-full">
                      {project.type}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 text-xs text-gray-500 mb-2">
                    <Calendar size={14} />
                    <span>{project.period}</span>
                  </div>
                  <p className="text-sm text-gray-600 mb-3 flex-grow">
                    {project.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <span className="text-xs font-medium bg-gray-100 text-gray-700 px-2 py-1 rounded">
                      {project.tech}
                    </span>
                    {project.url && (
                      <a
                        href={project.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-xs text-gray-700 hover:text-gray-900 transition-colors"
                      >
                        {project.link && project.link.includes('Play Store') ? (
                          <>
                            <PlayCircle size={16} className="mr-1" />
                            Play Store
                          </>
                        ) : (
                          <>
                            <ExternalLink size={16} className="mr-1" />
                            Visit
                          </>
                        )}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          {Array.from({ length: Math.ceil(projects.length / projectsPerPage) }).map((_, index) => (
            <motion.button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`mx-1 px-3 py-1 rounded-full text-xs font-medium ${
                currentPage === index + 1 ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {index + 1}
            </motion.button>
          ))}
        </div>
      </div>
    </section>
  );
}