import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Code } from 'lucide-react';

const menuItems = ['About', 'Projects', 'WorkHistory'];

export function Header() {
  // Fungsi untuk men-scroll ke bagian yang diinginkan dengan smooth scrolling
  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    const element = document.getElementById(sectionId.toLowerCase());
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
        {/* Logo sebagai representasi identitas, tanpa teks 'Aditia' untuk menghindari redundansi */}
        <Link to="/" aria-label="Homepage" className="flex items-center">
          <motion.div
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.5 }}
          >
            <Code className="text-primary" size={24} />
          </motion.div>
        </Link>

        {/* Menu navigasi dengan feedback visual yang responsif */}
        <div className="flex space-x-6">
          {menuItems.map((item, index) => (
            <motion.a
              key={index}
              href={`#${item.toLowerCase()}`}
              onClick={(e) => handleNavClick(e, item)}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
              className="group text-lg text-gray-700 hover:text-primary cursor-pointer relative"
              aria-label={`Navigate to ${item}`}
            >
              {item}
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-primary group-hover:w-full transition-all duration-500 ease-in-out" />
            </motion.a>
          ))}
        </div>
      </nav>
    </header>
  );
}