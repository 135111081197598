import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ArrowUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

export function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  // Menggunakan useRef untuk menyimpan timer debouncing agar nilainya tetap antar render
  const timeoutRef = useRef(null);

  const toggleVisibility = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      // Tampilkan tombol jika jarak scroll lebih dari 300px
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 100);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility, { passive: true });
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [toggleVisibility]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /*
    Fitur scroll-to-top yang dioptimalkan dengan animasi halus dan smooth scrolling 
    memberikan kesan profesional dan teknis. Fitur ini memudahkan pengguna untuk 
    menjelajahi website tanpa hambatan, menunjukkan bahwa perhatian detail terhadap UX 
    telah diterapkan secara serius.
  */
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          onClick={scrollToTop}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.1, rotate: 15 }}
          whileTap={{ scale: 0.9 }}
          className="fixed bottom-4 right-4 bg-primary text-primary-foreground p-2 rounded-full shadow-lg transition-all hover:opacity-80 focus:outline-none"
          aria-label="Scroll to top"
        >
          <ArrowUp size={24} />
        </motion.button>
      )}
    </AnimatePresence>
  );
}