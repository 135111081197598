import React from 'react';
import { Heart } from 'lucide-react';
import { motion } from 'framer-motion';

export function Footer() {
  return (
    <footer className="bg-background/50 backdrop-blur-sm border-t border-muted">
      <div className="container mx-auto px-6 py-8">
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="flex items-center justify-center text-sm text-muted-foreground">
            <span>Built with</span>
            <motion.div
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ repeat: Infinity, duration: 2 }}
              className="inline-flex mx-1 text-red-500"
            >
              <Heart size={14} />
            </motion.div>
            <span>by Aditia</span>
          </div>
  
          <div className="text-xs text-muted-foreground/60">
            &copy; {new Date().getFullYear()} All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}