import React from 'react';
import { motion } from 'framer-motion';
import { Calendar } from 'lucide-react';

const workExperiences = [
  {
    company: "Eikon Technology",
    position: "Cloud Engineer",
    period: "Sep 2024 - Present",
    description: [
      "Learning About GCP (Google Cloud Platform)",
      "Worked on projects for clients.",
      "This website is a platform that manages Google licenses through bulk and individual assignments, quota management, and seamless API integration."
    ]
  },
  {
    company: "PT Hero Intiputra",
    position: "IT Web Developer & Mobile Apps",
    period: "Nov 2013 - Sep 2024",
    description: [
      "Designed, developed, and maintained software applications, while managing business websites, and fixing bugs for both web and mobile applications.",
      "Created mobile applications to support business growth.",
      "Maintained domains and hosting to ensure operational continuity.",
      "Manage business websites, maintained domains and hosting",
      "Stay updated with emerging technologies and industry trends",
      "Integrated websites and mobile apps with APIs."
    ]
  },
  {
    company: "Altermyth",
    position: "Junior PHP Developer",
    period: "Jun 2011 - Mar 2013",
    description: [
      "Developed a download platform backend using PHP and MySQL.",
      "Tested websites, applications, and games for security and bugs.",
      "Created a project management website for effective monitoring."
    ]
  }
];

export function WorkHistory() {
  return (
    <section id="workhistory" className="py-20 bg-background">
      <div className="container mx-auto px-6 md:pl-24">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold mb-8 text-center"
        >
          Work History
        </motion.h2>
        {workExperiences.map((experience, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className="mb-6 p-4 border rounded shadow"
          >
            <div className="flex items-center space-x-2 mb-2">
              <Calendar size={18} className="text-primary" />
              <span className="text-sm text-muted-foreground">{experience.period}</span>
            </div>
            <h3 className="text-xl font-semibold">{experience.position} at {experience.company}</h3>
            <ul className="list-disc ml-6 mt-2 text-sm">
              {experience.description.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </section>
  );
}