import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

export default function Carousel({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const nextSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <>
      {/* Carousel dengan kontainer yang disesuaikan */}
      <div className="relative w-full h-48 overflow-hidden rounded-md">
        {images.map((image, index) => (
          <motion.img
            key={index}
            src={image}
            alt={`Project image ${index + 1}`}
            className="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            transition={{ duration: 0.5 }}
            onClick={() => openFullscreen(image)}
            loading="lazy"
          />
        ))}
        <button
          className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-40 text-white p-1 rounded-full"
          onClick={prevSlide}
        >
          <ChevronLeft size={20} />
        </button>
        <button
          className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-40 text-white p-1 rounded-full"
          onClick={nextSlide}
        >
          <ChevronRight size={20} />
        </button>
      </div>
      
      {/* AnimatePresence digunakan untuk animasi masuk/keluar fullscreen */}
      <AnimatePresence>
        {fullscreenImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
            onClick={closeFullscreen}
          >
            <button
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                closeFullscreen();
              }}
            >
              <X size={28} />
            </button>
            <img
              src={fullscreenImage}
              alt="Fullscreen view"
              className="max-w-[90vw] max-h-[90vh] object-contain"
              loading="lazy"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
} 